import React, { memo, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getShadowDetailsAction,
  ShadowProcessDetailI
} from '../../../redux/pdfSombra/pdfSombraActions';
import { useDispatch } from 'react-redux';
import { getDetallesPdfSombra } from '../../../services/getDetallesPdfSombra';
import { getPdfSombra } from '../../../services/getPdfSombra';

const useRenderPdfSombra = () => {
  const [pdfDetails, setpdfDetails] = useState<ShadowProcessDetailI | undefined>(undefined);
  const [pdfDownloads, setpdfDownloads] = useState(false);
  const [loading, setloading] = useState(true);
  const [searchParams] = useSearchParams();
  const [loadingPdf, setloadingPdf] = useState(false);

  const query = useParams();
  const dispatch = useDispatch();
  const crumbs = [
    { path: `/trackAnual?${searchParams?.get('prev')}`, title: 'Track Capacitación' },
    {
      path: `/trackAnual/detalledeTrackCapacitacion/${query.nomina}?${searchParams?.get('prev')}`,
      title: 'Detalle del Track Capacitación'
    },
    {
      path: `/trackAnual/detalledeTrackCapacitacion/${query.nomina}?${searchParams?.get('prev')}/pdf-sombra`,
      title: 'pdf sombra'
    }
  ];

  useEffect(() => {
    const getShadowDetails = async () => {
      const token = sessionStorage.getItem('token');
      const id = query.nomina;
      if (token === null || !id) return;
      try {
        const {
          proceso_sombra,
          status_donwload
        }: { proceso_sombra: ShadowProcessDetailI; status_donwload: boolean } =
          await getDetallesPdfSombra(token, id);
        dispatch(getShadowDetailsAction(proceso_sombra));
        setpdfDownloads(status_donwload);
        setpdfDetails(proceso_sombra);
      } catch (error) {
        console.log(error);
      } finally {
        setloading(false);
      }
    };
    getShadowDetails();
  }, [query]);


  const handleDownloadPdf = async () => {
    /*  window.print(); */
    const token = sessionStorage.getItem('token');
    const id = query.nomina;
    if (token === null || !id) return;
    setloadingPdf(true);
    try {
      const result = await getPdfSombra(id || '', token);
      setpdfDownloads(true);
      window.open(result.url, '_blank');
    } catch (error) {
      console.log(error);
    } finally {
      setloadingPdf(false);
    }
  };
  return { crumbs, loading, pdfDetails, pdfDownloads, loadingPdf, handleDownloadPdf };
};

export default useRenderPdfSombra;
