import React, { useEffect, useState } from 'react';
import Quit from '../../assets/quit.png';
import './editGroupsUser.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditDataSelectorSedes from '../editDataSelectors/editDataSelectorSedes';
import EditDataSelectorDireccion from '../editDataSelectors/editDataSelectorDireccion';
import EditDataSelectorSubdireccion from '../editDataSelectors/editDataSelectorSubdirecciones';
import EditDataSelectorRegionYastas from '../editDataSelectors/editDataSelectorRegionYastas';
import EditDataSelectorSubdireccionYastas from '../editDataSelectors/editDataSelectorSubdireccionYastas';
import EditDataSelectorGrupos from '../editDataSelectors/editDataSelectorGrupos';
import EditDataSelectorSubdirector from '../editDataSelectors/editDataSelectorSubdirector';
import EditDataSelectorHoteles from '../editDataSelectors/editDataSelectorHoteles';
import { updateMiembro } from '../../services/updateMiembro';

function EditGroupsUser({ isEditUser, grupo, colaborador, selectedDate }: any) {
  const [selectedStartDate, setSelectedStartDate] = useState<any>('');
  const [selectedFinishDate, setSelectedFinishDate] = useState<any>('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [finishDate, setFinishDate] = useState<Date | null>(new Date());
  const [groupsDate, setGroupsDate] = useState<string>(selectedDate);
  const [newDireccion, setNewDireccion] = useState<string>('');
  const [newSubdireccion, setNewSubdireccion] = useState<string>('');
  const [newRegionYastas, setNewRegionYastas] = useState<string>('');
  const [newSubdireccionYastas, setNewSubdireccionYastas] = useState<string>('');
  const [sede, setSede] = useState<string>('');
  const [asistencia, setAsistencia] = useState<boolean>(true);
  const [hospedaje, setHospedaje] = useState<boolean>(true);
  const [servicios, setServicios] = useState<boolean>(true);
  const [noches, setNoches] = useState<string>('');
  //console.log('grupo', grupo);
  //console.log('colaborador', colaborador);

  const closeEditUserModal = () => {
    isEditUser(false);
  };

  const onStartDateChange = (date: any) => {
    setStartDate(date);
    const dateFormatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setSelectedStartDate(dateFormatted);
  };

  const onFinishDateChange = (date: any) => {
    setFinishDate(date);
    const dateFormatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setSelectedFinishDate(dateFormatted);
  };

  const onAsistenciaChange = (e: any) => {
    const asistenciaOption = e.target.value;
    asistenciaOption === 'Asistencia' ? setAsistencia(true) : setAsistencia(false);
  };

  const onHospedajeChange = (e: any) => {
    const hospedajeOption = e.target.value;
    hospedajeOption === 'Si' ? setHospedaje(true) : setHospedaje(false);
  };

  const onServiciosChange = (e: any) => {
    const serviciosOption = e.target.value;
    serviciosOption === 'Si' ? setServicios(true) : setServicios(false);
  };

  const onNochesChange = (e: any) => {
    const noches = e.target.value;
    setNoches(noches);
  };

  const getDireccion = (direccion: string) => {
    setNewDireccion(direccion);
  };
  const getSubdireccion = (subdireccion: string) => {
    setNewSubdireccion(subdireccion);
  };
  const getSubdirector = (subdirector: string) => {
    console.log('pending');
  };
  const getNewRegionYastas = (regionYastas: string) => {
    setNewRegionYastas(regionYastas);
  };
  const getNewSubdireccionYastas = (subdireccionYastas: string) => {
    setNewSubdireccionYastas(subdireccionYastas);
  };
  const getSede = (sede: string) => {
    setSede(sede);
  };
  const getHotel = (hotel: string) => {
    console.log('pending');
  };
  const getGrupo = (grupo: string) => {
    console.log('pending');
  };

  const UpdatedInfo = {
    colaborador_id: colaborador.id,
    grupo_id: grupo.id,
    direccion: newDireccion,
    subdireccion: newSubdireccion,
    fecha_inicio: selectedStartDate,
    fecha_fin: selectedFinishDate,
    asistencia: asistencia,
    //subdirector_operaciones: null,
    region_yastas: newRegionYastas,
    subdireccion_yastas: newSubdireccionYastas,
    ubicacion: sede,
    hospedaje: hospedaje,
    //hotel: null,
    servicios: servicios,
    noches: noches
    ////grupo_id: grupo.id
  };

  const uploadColaboradorInfo = () => {
    const token: any = sessionStorage.getItem('token');
    const data: any = UpdatedInfo;
    updateMiembro(token, data).then((response) => {
      //setPostResponse(response);
      console.log(response);
    });
    closeEditUserModal();
  };

  // useEffect(() => {
  //   console.log(UpdatedInfo);
  // });

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container">
        <div className="colabs-filter-header">
          <p>Editar Colaborador</p>
          <img src={Quit} alt="quit-logo" onClick={closeEditUserModal} />
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option edit-colab-info">Colaborador</p>
          <div className="colab-info">
            <p className="colab-name">{colaborador.nombre}</p>
            <p className="colab-location">{colaborador.ubicacion}</p>
          </div>
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">Ubicación</p>
          <div className="direccion-selector">
            <p>Dirección</p>
            <EditDataSelectorDireccion getDireccion={getDireccion} />
          </div>
          <div className="direccion-selector subdireccion-selector">
            <p>Subdirección</p>
            <EditDataSelectorSubdireccion getSubdireccion={getSubdireccion} />
          </div>
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">Fecha de curso</p>
          <div className="date-picker-container">
            {/* <p className="label-title">Elige una semana</p>
            <p className="date-picker">Mes / Semana</p> */}
            <DatePicker
              selected={startDate}
              onChange={onStartDateChange}
              showYearDropdown
              dateFormatCalendar="MMMM"
              dateFormat="yyyy-MM-dd"
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              //startDate={startDate}
              //endDate={endDate}
              //selectsRange
            />
          </div>
          <div className="date-picker-container">
            {/* <p className="label-title">Elige una semana</p>
            <p className="date-picker">Mes / Semana</p> */}
            <DatePicker
              selected={finishDate}
              onChange={onFinishDateChange}
              showYearDropdown
              dateFormatCalendar="MMMM"
              dateFormat="yyyy-MM-dd"
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              //startDate={startDate}
              //endDate={endDate}
              //selectsRange
            />
          </div>
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">Asistencia</p>
          <div className="asistencia-options" onChange={onAsistenciaChange}>
            <label>
              <input type="radio" name="asistencia" value="Asistencia" />
              Asistencia
            </label>
            <label>
              <input type="radio" name="asistencia" value="Error de inscripción" />
              Error de inscripción
            </label>
            <label>
              <input type="radio" name="asistencia" value="Falta" />
              Falta
            </label>
          </div>
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">YASTAS</p>
          <div className="direccion-selector">
            <p>Subdirector</p>
            <EditDataSelectorSubdirector />
          </div>
          <div className="direccion-selector subdireccion-selector">
            <p>Región YASTAS</p>
            <EditDataSelectorRegionYastas getNewRegionYastas={getNewRegionYastas} />
          </div>
          <div className="direccion-selector subdireccion-selector">
            <p>Subdirección YASTAS</p>
            <EditDataSelectorSubdireccionYastas
              getNewSubdireccionYastas={getNewSubdireccionYastas}
            />
          </div>
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">Sede del curso</p>
          <div className="direccion-selector">
            <p>Sede</p>
            <EditDataSelectorSedes getSede={getSede} />
          </div>
          <div className="hospedaje">
            <p>Hospedaje</p>
            <div className="hospedaje-options" onChange={onHospedajeChange}>
              <label>
                <input type="radio" name="hospedaje" value="Si" />
                Si
              </label>
              <label>
                <input type="radio" name="hospedaje" value="=No" />
                No
              </label>
            </div>
          </div>
          <div className="direccion-selector subdireccion-selector">
            <p>Hotel</p>
            <EditDataSelectorHoteles />
          </div>
          <div className="hospedaje">
            <p>Servicios</p>
            <div className="hospedaje-options" onChange={onServiciosChange}>
              <label>
                <input type="radio" name="servicios" value="Si" />
                Si
              </label>
              <label>
                <input type="radio" name="servicios" value="No" />
                No
              </label>
            </div>
          </div>
          <div className="direccion-selector subdireccion-selector noches-container">
            <p>Noches</p>
            <input
              type="text"
              placeholder="Escribe el número de noches"
              className="noches-input"
              onChange={onNochesChange}
            />
          </div>
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">Grupo</p>
          <div className="direccion-selector subdireccion-selector">
            <p>Grupo</p>
            <EditDataSelectorGrupos groupsDate={groupsDate} />
          </div>
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={closeEditUserModal}>
            CANCELAR
          </button>
          <button className="accept-button" onClick={uploadColaboradorInfo}>
            GUARDAR CAMBIOS
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditGroupsUser;
