import React, { useEffect, useState, useMemo } from 'react';
import '../styles/styles.css';
import { getGrupos } from '../services/getGrupos';
import { useSelector } from 'react-redux';
import Upload from '../assets/upload-logo.png';
import Filter from '../assets/filter-logo.png';
import Compare from '../assets/compare-logo.png';
import Download from '../assets/download-logo.png';
import Send from '../assets/send.png';
import Build from '../assets/build-logo.png';
import ThreeDots from '../assets/three-dots.svg';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import EditLogo from '../assets/edit-logo.png';
import FormadorLogo from '../assets/formador-logo.png';
import GroupsFilterModal from '../components/groupsFilterModal/groupsFilterModal';
import UploadModal from '../components/uploadModal/uploadModal';
import UpdateCompararModal from '../components/updateCompararModal/updateCompararModal';
import { createGruposBasicos } from '../services/createGruposBasicos';
import { createGruposIntermedio, createGruposAvanzado } from '../services/createGruposNiveles';
import { uploadFaltas } from '../services/uplodadFaltas';
import { uploadFaltasIntermedio, uploadFaltasAvanzado } from '../services/uploadFaltasConNiveles';
import { downloadGrupos } from '../services/downloadGrupos';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditGroupsUser from '../components/editGroupsUser/editGroupsUser';
import EditGroupsFormador from '../components/editGroupsFormador/editGroupsFormador';
import EditGroupsSedeModal from '../components/editGroupsSedeModal/editGroupsSedeModal';
import { useSearchParams, useLocation } from 'react-router-dom';
import es from 'date-fns/locale/es';
import QuestionMark from '../assets/circle-info.svg';
import { uploadOperaciones } from '../services/uploadOperaciones';
import { isFriday, addDays, endOfMonth, addMonths } from 'date-fns';
import { AppState } from '../types/types';
import moment from 'moment';
import { format, lastDayOfMonth, startOfMonth, subDays } from 'date-fns';
import { uploadExcel } from '../services/uploadExcel';
import { uploadListasFinales } from '../services/uploadListasFinales';
import { envioDeInvitacionesFinal } from '../services/enviarInvitacionesFinal';
setDefaultLocale('es');

function Groups() {
  const location = useLocation();
  const [updateDate, setUpdateDate] = useState<string>('');

  const tipo = location.pathname.split('/')[2];
  const [grupos, setGrupos] = useState<any>(null);
  const [groupsFiltersModal, setGroupsFiltersModal] = useState<boolean>(false);
  const [compararGroupsModal, setCompararGroupsModal] = useState<boolean>(false);
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<any>(
    format(subDays(lastDayOfMonth(new Date()), 7), 'yyyy-MM-dd')
  );
  const [startDate, setStartDate] = useState<Date | null>(startOfMonth(new Date()));
  const [grupo, setGrupo] = useState<any>(null);
  const [colaborador, setColaborador] = useState<any>(null);
  const [crearGruposAns, setCrearGruposAns] = useState<boolean | null>(null);
  const [compararAns, setCompararAns] = useState<boolean | null>(null);
  const [_, setModalAbierto] = useState<boolean[]>([]);
  const [modalSedes, setModalSedes] = useState<boolean>(false);
  const [asignarFormadorModal, setAsignarFormadorModal] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>(format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'));
  const [uploadFaltasResponse, setUploadFaltasResponse] = useState<{
    success: boolean;
    message: string;
  } | null>(null);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFaltasSuccess, setUploadFaltasSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [uploadFaltasError, setUploadFaltasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [actionName, setActionName] = useState<string>('');
  const [uploadingMessage, setUploadingMessage] = useState('Cargando Datos...');
  const [envioInvitacionesSuccess, setEnvioInvitacionesSuccess] = useState<boolean | null>(null);
  const [envioInvitacionesError, setEnvioInvitacionesError] = useState<boolean | null>(null);
  const rolUsuario = useSelector((state: AppState) => state.user.userData?.role);

  const generateMonths = useMemo(() => {
    const months = [];
    let currentMonth = startOfMonth(selectedDate);
    const currentYear = currentMonth.getFullYear(); // Obtener el año de la fecha seleccionada

    while (currentMonth.getFullYear() === currentYear) {
      const startDate = startOfMonth(currentMonth);
      const endDate = endOfMonth(currentMonth);

      months.push({ startDate, endDate });

      // Mover al siguiente mes
      currentMonth = addMonths(currentMonth, 1);

      // Opcional: detener el bucle al final del año, dependiendo de la lógica que necesites
      if (tipo === 'avanzado' && currentMonth.getFullYear() > currentYear) {
        break;
      }

      // Si necesitas generar solo unos pocos meses para 'intermedio', podrías agregar una condición aquí
      // Por ejemplo, detener después de generar 6 meses para un tipo 'intermedio'
      if (tipo === 'intermedio' && months.length >= 6) {
        break;
      }
    }

    return months;
  }, [selectedDate, tipo]); // Dependencias: recomputar si cambia selectedDate o tipo // `tipo` es la dependencia que determina si generas por días o meses

  const [searchParams] = useSearchParams();

  const onDatePickerChange = (date: any) => {
    setStartDate(date);
    const dateFormatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setSelectedDate(dateFormatted);
  };

  const isGroupsFiltersModal = (openFiltersModal: boolean) => {
    setGroupsFiltersModal(openFiltersModal);
  };

  const isCompararModal = (openCompararModal: boolean) => {
    setCompararGroupsModal(openCompararModal);
  };

  const isEditUser = (openEditUserModal: boolean) => {
    setEditUserModal(openEditUserModal);
  };

  const isAsignarFormador = (openAsignarFormadorModal: boolean) => {
    setAsignarFormadorModal(openAsignarFormadorModal);
  };

  const isPostSuccess = (postResponse: boolean) => {
    setCompararAns(postResponse);
  };

  const closeAlert = () => {
    setCrearGruposAns(null);
    setCompararAns(null);
    if (isUploading) {
      setIsUploading(false);
    }
    setUploadFaltasSuccess(false);
    setUploadFaltasError(false);
  };

  const isLoadingAlert = (loadAlert: boolean) => {
    setLoadingData(loadAlert);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const openEditUser = (item_grupo: any, item_colaborador: any) => {
    setEditUserModal(true);
    setGrupo(item_grupo);
    setColaborador(item_colaborador);
  };

  const openAsignarFormador = (item_grupo: any) => {
    setAsignarFormadorModal(true);
    setGrupo(item_grupo);
  };

  const isEditarSedes = (closeEditSedes: boolean) => {
    setModalSedes(false);
  };

  const createGrupos = () => {
    setLoadingData(true);
    const token = sessionStorage.getItem('token');

    if (token === null) {
      console.error('No se encontró el token');
      setLoadingData(false);
      return;
    }

    if (tipo === 'basico') {
      createGruposBasicos(token)
        .then((result) => {
          console.log(result);
          setCrearGruposAns(result.success);
        })
        .catch((error) => {
          console.error('Error al crear grupos', error);
        })
        .finally(() => {
          setLoadingData(false);
        });
    } else if (tipo == 'intermedio') {
      createGruposIntermedio(token, tipo)
        .then((result) => {
          console.log(result);
          setCrearGruposAns(result.success);
        })
        .catch((error) => {
          console.error('Error al crear grupos de intermedio', error);
        })
        .finally(() => {
          setLoadingData(false);
        });
    } else {
      createGruposAvanzado(token, tipo)
        .then((result) => {
          console.log(result);
          setCrearGruposAns(result.success);
        })
        .catch((error) => {
          console.error('Error al crear grupos de avanzado', error);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }

    setTimeout(() => {
      setCrearGruposAns(null);
    }, 5000);
  };

  const isFiltering = (filter: string) => {
    setFilter(filter);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

  const nextPage = () => {
    if (pagination < paginationInfo.totalPages) {
      setPagination(pagination + 1);
    }
  };

  const previousPage = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  };

  const openEditSede = (item_grupo: any) => {
    setGrupo(item_grupo);
    setModalSedes(true);
  };

  const downloadGrupo = () => {
    const token = sessionStorage.getItem('token');

    if (token) {
      downloadGrupos(token, selectedDate, endDate, tipo)
        .then((result) => {
          window.open(result.url);
        })
        .catch((error) => {
          console.error('Error al descargar archivo de grupo', error);
        });
    }
  };

  const enviarInvitaciones = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      setUploadingMessage('Enviando invitaciones...');
      setLoadingData(true); // Activa la alerta de carga
      envioDeInvitacionesFinal(token, tipo)
        .then((response) => {
          if (response.success) {
            console.log('Invitaciones enviadas con éxito', response);
            setEnvioInvitacionesSuccess(true);
            setSuccessMessage('Las invitaciones se han enviado correctamente.');
          } else {
            // Manejar una respuesta no exitosa si es necesario
            setEnvioInvitacionesError(true);
            setErrorMessage('La respuesta fue no exitosa.');
          }
        })
        .catch((error) => {
          console.error('Error al enviar invitaciones', error);
          setEnvioInvitacionesError(true);
          setErrorMessage('Hubo un error al enviar las invitaciones.');
        })
        .finally(() => {
          setLoadingData(false);
          setUploadingMessage('Cargando Datos...');
          // Desactiva la alerta de carga independientemente del resultado
          // Restablecer el estado de éxito/error después de un tiempo
          setTimeout(() => {
            setEnvioInvitacionesSuccess(null);
            setEnvioInvitacionesError(null);
          }, 5000);
        });
    }
  };

  useEffect(() => {
    apiCall();

    if (compararAns === true || crearGruposAns === true) {
      apiCall();
      setLoadingData(false);
      setTimeout(() => {
        setCompararAns(null);
        setCrearGruposAns(null);
      }, 5000);
    }
    if (compararAns === false || crearGruposAns === false) {
      setLoadingData(false);
      setTimeout(() => {
        setCompararAns(null);
        setCrearGruposAns(null);
      }, 5000);
    }
  }, [
    tipo,
    location.pathname,
    rowsLimit,
    filter,
    selectedDate,
    crearGruposAns,
    compararAns,
    pagination,
    searchParams,
    endDate
  ]);

  useEffect(() => {
    setFilter('');
  }, [tipo, location.pathname]);

  const apiCall = () => {
    const token = sessionStorage.getItem('token');

    if (token) {
      getGrupos(token, rowsLimit, pagination, filter, selectedDate, endDate, tipo!)
        .then((result) => {
          setGrupos(
            tipo == 'avanzado'
              ? [
                  {
                    nombre: null,
                    colaboradores: result?.data?.colaboradores
                  }
                ]
              : result?.data?.grupos
          );
          setUpdateDate(result?.data?.fecha);
          setPaginationInfo(result?.data?.pagination);
          setModalAbierto(result?.data?.grupos?.map(() => false));
        })
        .catch((error) => {
          console.error('Error al obtener usuario', error);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  };

  const generateDates = useMemo(() => {
    const today = new Date(lastDayOfMonth(selectedDate));
    const startOfTheMonth = startOfMonth(today);
    const endOfTheMonth = lastDayOfMonth(today);
    let firstFridayOfNextMonth = startOfMonth(addMonths(today, 1));
    while (!isFriday(firstFridayOfNextMonth)) {
      firstFridayOfNextMonth = addDays(firstFridayOfNextMonth, 1);
    }

    let endDate = isFriday(endOfTheMonth) ? endOfTheMonth : firstFridayOfNextMonth;
    const tempDates = [];

    while (endDate >= startOfTheMonth) {
      while (!isFriday(endDate)) {
        endDate = subDays(endDate, 1);
      }

      let startDate = subDays(endDate, 4);
      startDate = startDate < startOfTheMonth ? startOfTheMonth : startDate;

      tempDates.push({
        startDate: startDate,
        endDate: endDate
      });

      endDate = subDays(endDate, 7);
    }

    return tempDates.reverse();
  }, [startDate]);

  const onClose = () => {
    setUpdateModal(false);
  };

  const handleCargarFaltasClick = () => {
    setActionName('faltas');
    setUpdateModal(true);
  };

  const handleCargarOperacionesClick = () => {
    setActionName('operaciones');
    setUpdateModal(true);
  };

  const handleCargarConcentradoVirtualClick = () => {
    setActionName('lista virtual');
    setUpdateModal(true);
  };

  const handleCargarConcentradoPresencialClick = () => {
    setActionName('lista presencial');
    setUpdateModal(true);
  };

  const handleCargarConcentradoClick = () => {
    setActionName('concentrado');
    setUpdateModal(true);
  };

  const handleFileUpload = async (base64Data: string, fileName: string) => {
    setLoadingData(true);
    setIsUploading(true);
    setUploadingMessage('Cargando Datos...');

    setTimeout(() => {
      setLoadingData(false);
      setUploadingMessage('Se subirá el excel pronto');

      setTimeout(async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
          setErrorMessage('Error: No se encontró el token de sesión.');
          setUploadFaltasError(true);
          setIsUploading(false);
          setTimeout(closeAlert, 2000);
          return;
        }

        let response;
        try {
          if (actionName === 'faltas') {
            if (tipo === 'basico') {
              response = await uploadFaltas(base64Data, token);
            } else if (tipo == 'intermedio') {
              response = await uploadFaltasIntermedio(base64Data, tipo, token); // Corregido el orden de los argumentos
            } else {
              response = await uploadFaltasAvanzado(base64Data, tipo, token); // Corregido el orden de los argumentos
            }
          } else if (actionName === 'operaciones') {
            response = await uploadOperaciones(base64Data, token);
          } else if (actionName === 'lista presencial') {
            response = await uploadListasFinales(base64Data, token, tipo, 'presencial');
          } else if (actionName === 'lista virtual') {
            response = await uploadListasFinales(base64Data, token, tipo, 'virtual');
          } else if (actionName === 'concentrado') {
            response = await uploadExcel(base64Data, token);
          } else {
            throw new Error('Acción no reconocida');
          }

          setIsUploading(false);

          if (response && response.success) {
            setSuccessMessage(`La subida del archivo ${fileName} fue exitosa.`);
            setUploadFaltasSuccess(true);
          } else {
            setErrorMessage(response.message || 'Error en la carga del archivo.');
            setUploadFaltasError(true);
          }
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Error inesperado en la carga del archivo.';
          setErrorMessage(errorMessage);
          setUploadFaltasError(true);
        } finally {
          setTimeout(closeAlert, 2000);
        }
      }, 2000);
    }, 2000);
  };

  return (
    <div className="container colaboradores">
      {updateDate !== '' && updateDate !== undefined && (
        <h4
          style={{ margin: '4px 0' }}
        >{`Última actualización: ${moment.utc(updateDate).format('DD/MM/YYYY')}`}</h4>
      )}
      {loadingData === true && (
        <div className="update-file-response-container-info">
          <div className="update-file-message">
            <img src={QuestionMark} />
            <p>{uploadingMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {isUploading && (
        <div className="update-file-response-container-info">
          <div className="update-file-message">
            <img src={QuestionMark} />
            <p>{uploadingMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {(compararAns === true ||
        uploadFaltasSuccess === true ||
        envioInvitacionesSuccess === true) && (
        <div className="update-file-response-container-success">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>{successMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {(compararAns === false || uploadFaltasError === true || envioInvitacionesError === true) && (
        <div className="update-file-response-container-error">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>{errorMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {crearGruposAns === true && (
        <div className="update-file-response-container-success">
          <div className="update-file-message">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Los grupos se crearon satisfactoriamente.</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {crearGruposAns === false && (
        <div className="update-file-response-container grupos-alert">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>Hubo un problema y no se crearon los grupos satisfactoriamente.</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateModal && (
        <UploadModal
          onClose={onClose}
          uploadService={handleFileUpload}
          title={`Cargar Archivo de ${actionName.charAt(0).toUpperCase() + actionName.slice(1)}`} // Capitaliza la primera letra
          isLoading={isUploading}
          setIsLoading={setLoadingData}
          successCallback={(message) => {
            console.log(message);
          }}
          errorCallback={(message) => {
            console.error(message);
          }}
        />
      )}
      {uploadFaltasResponse && (
        <div
          className={`update-file-response-container ${uploadFaltasResponse.success ? 'grupos-alert' : 'grupos-alert'}`}
        >
          <div
            className={`update-file-message ${uploadFaltasResponse.success ? 'update-file-success' : 'update-file-error'}`}
          >
            <img
              src={uploadFaltasResponse.success ? SuccessLogo : ErrorLogo}
              alt={uploadFaltasResponse.success ? 'success-logo' : 'error-logo'}
            />
            <p>{uploadFaltasResponse.message}</p>
            <img
              src={Quit}
              alt="quit-logo"
              onClick={() => setUploadFaltasResponse(null)}
              className="close-alert"
            />
          </div>
        </div>
      )}

      <div className="fecha-grupos">
        <div>
          <label htmlFor="picker">Elige un mes</label>
          <DatePicker
            id="picker"
            selected={startDate}
            locale={es}
            onChange={onDatePickerChange}
            showMonthYearPicker
            showFullMonthYearPicker
            dateFormatCalendar="MMMM"
            dateFormat="MMMM yyyy"
            yearDropdownItemNumber={50}
            className="picker"
            //startDate={startDate}
            //endDate={endDate}
            //selectsRange
          />
        </div>
        <div className="dates">
          {tipo === 'intermedio' || tipo === 'avanzado'
            ? generateMonths.map(({ startDate, endDate }, index) => (
                <a
                  className={selectedDate === format(startDate, 'yyyy-MM-dd') ? 'active-date' : ''}
                  key={index}
                  onClick={() => {
                    setSelectedDate(format(startDate, 'yyyy-MM-dd'));
                    setEndDate(format(endDate, 'yyyy-MM-dd'));
                  }}
                >
                  01 - {format(endDate, 'dd MMMM', { locale: es })}
                </a>
              ))
            : generateDates.map(({ startDate, endDate }, index) => (
                <a
                  className={selectedDate === format(startDate, 'yyyy-MM-dd') ? 'active-date' : ''}
                  key={index}
                  onClick={() => {
                    setSelectedDate(format(startDate, 'yyyy-MM-dd'));
                    setEndDate(format(endDate, 'yyyy-MM-dd'));
                  }}
                >
                  {format(startDate, 'MMMM') == format(endDate, 'MMMM') ? (
                    <>
                      {format(startDate, 'dd')} - {format(endDate, 'dd MMMM', { locale: es })}
                    </>
                  ) : (
                    <>
                      {format(startDate, 'dd MMMM', { locale: es })} -{' '}
                      {format(endDate, 'dd MMMM', { locale: es })}
                    </>
                  )}
                  {/*index === generateDates.length - 1 && transformLastEndDate*/}
                </a>
              ))}
        </div>
      </div>
      <div className="table-options table-options-groups ">
        <div className="table-options-init">
          {rolUsuario !== 'formador' && (
            <>
              <div className="option-grupos" onClick={() => setCompararGroupsModal(true)}>
                <img src={Compare} alt="compare-logo" />
                <p>CURSOS ONLINE</p>
              </div>
              <div className="option-grupos" onClick={() => createGrupos()}>
                <img src={Upload} alt="upload-logo" />
                <p>ACTUALIZAR</p>
              </div>
              <div className="option-grupos" onClick={handleCargarFaltasClick}>
                <img src={Upload} alt="upload-logo" />
                <p>FALTAS</p>
              </div>
              <div className="option-grupos" onClick={handleCargarOperacionesClick}>
                <img src={Upload} alt="upload-logo" />
                <p>OPERACIONES</p>
              </div>
              <div className="option-grupos" onClick={handleCargarConcentradoVirtualClick}>
                <img src={Upload} alt="upload-logo" />
                <p>VIRTUAL</p>
              </div>
              <div className="option-grupos" onClick={handleCargarConcentradoPresencialClick}>
                <img src={Upload} alt="upload-logo" />
                <p>PRESENCIAL</p>
              </div>
              <div className="option-grupos" onClick={handleCargarConcentradoClick}>
                <img src={Upload} alt="upload-logo" />
                <p>CONCENTRADO</p>
              </div>
            </>
          )}
          <div className="option-grupos" onClick={() => setGroupsFiltersModal(true)}>
            <img src={Filter} alt="filter-logo" />
            <p>FILTRAR</p>
          </div>
          {groupsFiltersModal && (
            <GroupsFilterModal
              isGroupsFiltersModal={isGroupsFiltersModal}
              isFiltering={isFiltering}
              filterString={filter}
            />
          )}
          {compararGroupsModal && (
            <UpdateCompararModal
              setSuccessMessage={setSuccessMessage}
              isCompararModal={isCompararModal}
              isPostSuccess={isPostSuccess}
              isLoadingAlert={isLoadingAlert}
              tipo={tipo}
            />
          )}
          <div className="option-group">
            <div className="option option-descargar" onClick={downloadGrupo}>
              <img src={Download} alt="compare-logo" />
              <p>DESCARGAR</p>
            </div>
            {rolUsuario === 'admin' && (
              <div className="option option-descargar" onClick={enviarInvitaciones}>
                <img src={Send} alt="Enviar invitaciones" />
                <p>ENVIAR INVITACIONES</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalSedes && <EditGroupsSedeModal isEditarSedes={isEditarSedes} grupo={grupo} />}
      {editUserModal && (
        <EditGroupsUser
          isEditUser={isEditUser}
          grupo={grupo}
          colaborador={colaborador}
          selectedDate={selectedDate}
        />
      )}
      {asignarFormadorModal && (
        <EditGroupsFormador isAsignarFormador={isAsignarFormador} grupo={grupo} />
      )}
      {grupos == null ? (
        'No Data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header groups-header">
              <tr>
                <th key="acciones">Acciones</th>
                <th key="nomina" className={!grupos.length ? 'thead-start' : 'thead'}>
                  Nómina
                </th>
                <th key="colaborador">Colaborador</th>
                <th key="puesto">Puesto</th>
                <th key="asistencia">Asistencia</th>
                <th key="razon_inasistencia">Motivo de falta</th>
                <th key="ubicacion">OS</th>
                <th key="direccion">Dirección</th>
                <th key="subdireccion">Subdirección</th>
                <th key="rubro">Rubro</th>
                <th key="negocio">Negocio</th>
                <th key="ruta_aprendizaje">Ruta de aprendizaje</th>
                <th key="fecha_inicio_online">Inicio curso en línea inicial</th>
                <th key="fecha_inicio">Inicio curso</th>
                <th key="fecha_fin">Fin curso</th>
                {tipo != 'avanzado' && <th key="modalidad">Modalidad</th>}
                <th key="subdirector_operaciones">Subdirector de operaciones</th>
                <th key="region_yastas">Región Yastas</th>
                <th key="subdireccion_yastas">Subdirección Yastas</th>
                {tipo != 'avanzado' && (
                  <>
                    <th key="sede">Sede Grupo</th>
                    <th key="sede1">Sede 1</th>
                    <th key="sede2">Sede 2</th>
                    <th key="hospedaje">Hospedaje</th>
                    <th key="hotel">Hotel</th>
                    <th key="servicios">Servicios</th>
                    <th key="check_in">Check in</th>
                  </>
                )}
                <th key="genero">Género</th>
                <th key="noches">Noches</th>
                <th key="os_multiproducto">OS Multiproducto</th>
                <th key="referidas_concredito">Referidas concredito (CM, CCR, CI)</th>
                {tipo != 'avanzado' && (
                  <>
                    <th key="programa">Programa</th>
                    <th key="avance">Avance</th>
                    <th key="estatus_avance">Estatus</th>
                  </>
                )}

                {/*  <th>Última actualización</th> */}
              </tr>
            </thead>
            {grupos.map((item_grupo: any, index: number) => (
              <>
                {tipo != 'avanzado' && (
                  <div className="sede-selector-container td">
                    <p>{item_grupo.nombre === null ? 'Nombre del Grupo' : item_grupo.nombre}</p>
                    <div
                      className="dropdown"
                      style={{
                        marginTop: '10px'
                      }}
                    >
                      <span>
                        <img src={ThreeDots} alt="menu" className="three-dots" />
                      </span>
                      <div className="dropdown-content">
                        <div onClick={() => openEditSede(item_grupo)}>
                          <img src={Build} alt="build-logo" />
                          <div className="edit-button-container">
                            <p
                              style={{
                                fontWeight: 500
                              }}
                            >
                              Editar Sede
                            </p>
                          </div>
                        </div>
                        <div onClick={() => openAsignarFormador(item_grupo)}>
                          <img src={FormadorLogo} alt="formador-logo" />
                          <p
                            style={{
                              fontWeight: 500
                            }}
                          >
                            Asignar Formador
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <tbody key={index} className="data-table groups-table">
                  {item_grupo.colaboradores.map((item_colaborador: any, index: number) => (
                    <tr key={index}>
                      <td key="acciones" className="td">
                        <img
                          src={EditLogo}
                          alt="edit-logo"
                          onClick={() => openEditUser(item_grupo, item_colaborador)}
                          className="edit-logo"
                        />
                      </td>
                      <td key="nomina" className={!grupos.length ? 'td-start' : 'td'}>
                        {item_colaborador.nomina}
                      </td>
                      <td key="colaborador">{item_colaborador.nombre}</td>
                      <td key="puesto">{item_colaborador.puesto}</td>
                      <td key="asistencia">
                        {item_colaborador.asistencia === true ? (
                          <img src={SuccessLogo} />
                        ) : item_colaborador.asistencia === false ? (
                          <img src={ErrorLogo} />
                        ) : (
                          ''
                        )}
                      </td>
                      <td key="razon_inasistencia">
                        {item_colaborador.razon_inasistencia === null
                          ? 'N/A'
                          : item_colaborador.razon_inasistencia}
                      </td>
                      <td key="ubicacion">{item_colaborador.ubicacion}</td>
                      <td key="direccion">{item_colaborador.direccion}</td>
                      <td key="subdireccion">{item_colaborador.subdireccion}</td>
                      <td key="rubro">{item_colaborador.rubro}</td>
                      <td key="negocio">{item_colaborador.negocio}</td>
                      <td key="ruta_aprendizaje">
                        {item_colaborador?.ruta_aprendizaje || item_grupo?.ruta_aprendizaje}
                      </td>
                      <td key="fecha_inicio_online">
                        {item_grupo.fecha_inicio_online !== null
                          ? moment.utc(item_grupo.fecha_inicio_online).format('DD/MM/YYYY')
                          : ''}
                      </td>
                      <td key="fecha_inicio">
                        {item_grupo.fecha_inicio !== null
                          ? moment.utc(item_grupo.fecha_inicio).format('DD/MM/YYYY')
                          : ''}
                      </td>
                      <td key="fecha_fin">
                        {item_grupo.fecha_fin !== null
                          ? moment.utc(item_grupo.fecha_fin).format('DD/MM/YYYY')
                          : ''}
                      </td>
                      {tipo != 'avanzado' && <td key="modalidad">{item_grupo.modalidad}</td>}
                      <td key="subdirector_operaciones">
                        {item_colaborador.subdirector_operaciones}
                      </td>
                      <td key="region_yastas">{item_colaborador.region_yastas}</td>
                      <td key="subdireccion_yastas">{item_colaborador.subdireccion_yastas}</td>
                      {tipo != 'avanzado' && (
                        <>
                          <td key="sede">{item_grupo.sede}</td>
                          <td key="sede1">{item_colaborador.sede1}</td>
                          <td key="sede2">{item_colaborador.sede2}</td>
                          <td key="hospedaje">
                            {item_colaborador.hospedaje === true ? 'Si' : 'No'}
                          </td>
                          <td key="hotel">{item_grupo.hotel}</td>
                          <td key="servicios">
                            {item_colaborador.servicios === true
                              ? 'Cena, desayuno, comida y café'
                              : 'Sin Servicios'}
                          </td>
                          <td key="check_in">
                            {item_colaborador.check_in !== null
                              ? moment.utc(item_colaborador.check_in).format('DD/MM/YYYY')
                              : ''}
                          </td>
                        </>
                      )}
                      <td key="genero">{item_colaborador.genero}</td>
                      <td key="noches">{item_colaborador.noches}</td>
                      <td key="os_multiproducto">{item_colaborador.os_multiproducto}</td>
                      <td key="referidas_concredito">{item_colaborador.referidas_concredito}</td>
                      {tipo != 'avanzado' && (
                        <>
                          <td key="programa">{item_grupo.programa}</td>
                          <td key="avance" className="progress-container">
                            <div className="progress-bar-background">
                              <div
                                className="progress-bar-foreground"
                                style={{ width: `${item_colaborador.avance}%` }}
                              ></div>
                            </div>
                            <p className="progress-text">{item_colaborador.avance}%</p>
                          </td>
                          <td key="estatus_avance">{item_colaborador.estatus_avance}</td>
                        </>
                      )}
                      {/*     <td>
                        {item_colaborador.updatedAt !== null
                          ? moment.utc(item_colaborador.updatedAt).format('DD/MM/YYYY')
                          : ''}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Grupos por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Groups;
