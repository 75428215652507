import React, { memo } from 'react';
import Table1 from './components/Table1';
import './RenderPdfSombra.scss';
import TableGraph3 from './components/TableGraph3';
import Table2 from './components/Table2';
import TablaRubricaDetail7 from './components/TablaRubricaDetail7';
import TablaRubricaDetailPar9 from './components/TablaRubricaDetailPar9';
import { Chart, registerables } from 'chart.js';
import BreadCrumb from '../../components/breadCrumb/breadCrumb';
import CapacitacionStyles from '../../styles/Detalles.module.css';
import useRenderPdfSombra from './hooks/useRenderPdfSombra';

Chart.register(...registerables);

const RenderPdfSombra = memo(() => {
  const { crumbs, loading, pdfDetails, pdfDownloads, handleDownloadPdf, loadingPdf } =
    useRenderPdfSombra();

  /* const downloadPDF = () => {
  if (chartRef.current) {
    setloading(true); // Indicador de carga mientras se genera el PDF
    const input = chartRef.current;

    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
        });

        const imgWidth = 210; // Ancho en mm para A4
        const pageHeight = 297; // Alto en mm para A4
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Mantener relación de aspecto

        let heightLeft = imgHeight;
        let position = 0;

        // Añade la primera página
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Si queda contenido, añadir nuevas páginas
        while (heightLeft > 0) {
          position = heightLeft > 0 ? -pageHeight : 0; // Mueve la posición en la página siguiente
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save('radar-chart-with-tables.pdf');
      })
      .catch((error) => {
        console.error('Error al generar el canvas:', error);
      })
      .finally(() => {
        setloading(false); // Desactivar el indicador de carga
      });
  }
}; */

  if (loading)
    return (
      <div className={`${CapacitacionStyles['container']} sombra-specs-body`}>
        <BreadCrumb crumbs={crumbs} />
        <div className="sombra-inform">
          <h2>Cargando pdf</h2>
        </div>
      </div>
    );

  if (!pdfDetails)
    return (
      <div className={`${CapacitacionStyles['container']} sombra-specs-body`}>
        <BreadCrumb crumbs={crumbs} />

        <div className="sombra-inform">
          <h2>No existe un pdf sombra para este usuario</h2>
        </div>
      </div>
    );
  return (
    <div className={`${CapacitacionStyles['container']} sombra-specs-body`}>
      <BreadCrumb crumbs={crumbs} />

      <button
        className="pdf-sombra-download-button"
        disabled={loadingPdf}
        onClick={handleDownloadPdf}
      >
        {loadingPdf
          ? 'Creando archivo'
          : pdfDownloads
            ? 'Volver a generar pdf sombra'
            : 'Generar pdf sombra'}
      </button>

      <div className="sombra-inform">
        <div className="banner" />
        <h1>Proceso Sombra</h1>
        <h3>Resultados Nivel Básico Capacitación</h3>
        <Table1 />
        <Table2 />
        <TableGraph3 />
        <TablaRubricaDetail7 />
        <br />
        <TablaRubricaDetailPar9 />
      </div>
    </div>
  );
});
RenderPdfSombra.displayName = 'RenderPdfSombra';
export default RenderPdfSombra;
