const positions = [
  'DIRECTOR REGIONAL VENTAS CENTRO',
  'DIRECTOR REGIONAL VENTAS CENTRO NORTE',
  'DIRECTOR REGIONAL VENTAS METRO',
  'DIRECTOR REGIONAL VENTAS NORESTE',
  'DIRECTOR REGIONAL VENTAS NORTE CENTRO',
  'DIRECTOR REGIONAL VENTAS OCCIDENTE',
  'DIRECTOR REGIONAL VENTAS OCCIDENTE',
  'DIRECTOR REGIONAL VENTAS PACIFICO',
  'DIRECTOR REGIONAL VENTAS PENINSULA',
  'DIRECTOR REGIONAL VENTAS SUR',
  'DIRECTOR REGIONAL VENTAS SURESTE',
  'INC SUBDIRECTOR REGIONAL VTS PACIFICO 09',
  'INC SUBDIRECTOR REGIONAL VTS PACIFICO 09',
  'SUBDIRECTOR REGIONAL VTS CENTRO 01',
  'SUBDIRECTOR REGIONAL VTS CENTRO 01',
  'SUBDIRECTOR REGIONAL VTS CENTRO 02',
  'SUBDIRECTOR REGIONAL VTS CENTRO 03',
  'SUBDIRECTOR REGIONAL VTS CENTRO 04',
  'SUBDIRECTOR REGIONAL VTS CENTRO 05',
  'SUBDIRECTOR REGIONAL VTS CENTRO 06',
  'SUBDIRECTOR REGIONAL VTS CENTRO 07',
  'SUBDIRECTOR REGIONAL VTS CENTRO 08',
  'SUBDIRECTOR REGIONAL VTS CENTRO 09',
  'SUBDIRECTOR REGIONAL VTS CENTRO 10',
  'SUBDIRECTOR REGIONAL VTS CENTRO 11',
  'SUBDIRECTOR REGIONAL VTS CENTRO 12',
  'SUBDIRECTOR REGIONAL VTS CENTRO 13',
  'SUBDIRECTOR REGIONAL VTS CENTRO 14',
  'SUBDIRECTOR REGIONAL VTS CENTRO 15',
  'SUBDIRECTOR REGIONAL VTS CENTRO 16',
  'SUBDIRECTOR REGIONAL VTS CENTRO 17',
  'SUBDIRECTOR REGIONAL VTS CENTRO 19',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 01',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 02',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 05',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 06',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 08',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 09',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 10',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 11',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 11',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 14',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 15',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 16',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE 17',
  'SUBDIRECTOR REGIONAL VTS METRO 02',
  'SUBDIRECTOR REGIONAL VTS METRO 03',
  'SUBDIRECTOR REGIONAL VTS METRO 04',
  'SUBDIRECTOR REGIONAL VTS METRO 05',
  'SUBDIRECTOR REGIONAL VTS METRO 06',
  'SUBDIRECTOR REGIONAL VTS METRO 07',
  'SUBDIRECTOR REGIONAL VTS METRO 08',
  'SUBDIRECTOR REGIONAL VTS METRO 09',
  'SUBDIRECTOR REGIONAL VTS METRO 10',
  'SUBDIRECTOR REGIONAL VTS METRO 11',
  'SUBDIRECTOR REGIONAL VTS METRO 13',
  'SUBDIRECTOR REGIONAL VTS METRO 16',
  'SUBDIRECTOR REGIONAL VTS METRO 18',
  'SUBDIRECTOR REGIONAL VTS NORESTE 01',
  'SUBDIRECTOR REGIONAL VTS NORESTE 02',
  'SUBDIRECTOR REGIONAL VTS NORESTE 04',
  'SUBDIRECTOR REGIONAL VTS NORESTE 05',
  'SUBDIRECTOR REGIONAL VTS NORESTE 06',
  'SUBDIRECTOR REGIONAL VTS NORESTE 07',
  'SUBDIRECTOR REGIONAL VTS NORESTE 08',
  'SUBDIRECTOR REGIONAL VTS NORESTE 09',
  'SUBDIRECTOR REGIONAL VTS NORESTE 10',
  'SUBDIRECTOR REGIONAL VTS NORESTE 11',
  'SUBDIRECTOR REGIONAL VTS NORESTE 12',
  'SUBDIRECTOR REGIONAL VTS NORESTE 13',
  'SUBDIRECTOR REGIONAL VTS NORESTE 14',
  'SUBDIRECTOR REGIONAL VTS NORESTE 15',
  'SUBDIRECTOR REGIONAL VTS NORESTE 16',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 02',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 06',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 10',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 11',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 14',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 15',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO 16',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 01',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 02',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 02',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 03',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 04',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 04',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 04',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 06',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 07',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 08',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 09',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 10',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 11',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE 12',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 01',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 02',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 02',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 04',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 05',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 05',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 06',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 07',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 07',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 08',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 09',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 10',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 11',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 12',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 13',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 14',
  'SUBDIRECTOR REGIONAL VTS PACIFICO 17',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 01',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 02',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 03',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 04',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 05',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 06',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 07',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 07',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 08',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 09',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 10',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 10',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 11',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 12',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 13',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 15',
  'SUBDIRECTOR REGIONAL VTS PENINSULA 16',
  'SUBDIRECTOR REGIONAL VTS SUR 02',
  'SUBDIRECTOR REGIONAL VTS SUR 04',
  'SUBDIRECTOR REGIONAL VTS SUR 05',
  'SUBDIRECTOR REGIONAL VTS SUR 09',
  'SUBDIRECTOR REGIONAL VTS SUR 10',
  'SUBDIRECTOR REGIONAL VTS SUR 17',
  'SUBDIRECTOR REGIONAL VTS SUR 18',
  'SUBDIRECTOR REGIONAL VTS SUR 19',
  'SUBDIRECTOR REGIONAL VTS SUR 20',
  'SUBDIRECTOR REGIONAL VTS SUR 21',
  'SUBDIRECTOR REGIONAL VTS SUR 22',
  'SUBDIRECTOR REGIONAL VTS SURESTE 01',
  'SUBDIRECTOR REGIONAL VTS SURESTE 03',
  'SUBDIRECTOR REGIONAL VTS SURESTE 04',
  'SUBDIRECTOR REGIONAL VTS SURESTE 06',
  'SUBDIRECTOR REGIONAL VTS SURESTE 08',
  'SUBDIRECTOR REGIONAL VTS SURESTE 09',
  'SUBDIRECTOR REGIONAL VTS SURESTE 09',
  'SUBDIRECTOR REGIONAL VTS SURESTE 13',
  'SUBDIRECTOR REGIONAL VTS SURESTE 14',
  'LIDER CREDITO GRUPAL',
  'SUBGERENTE GRUPAL',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE CI',
  'SUBDIRECTOR REGIONAL VTS CENTRO CI',
  'SUBDIRECTOR REGIONAL VENTAS CI',
  'SUBDIRECTOR REGIONAL VTS NORTE CENTRO CI',
  'SUBDIRECTOR REGIONAL VTS SURESTE CI',
  'SUBDIRECTOR REGIONAL VTS CENTRO CI',
  'SUBDIRECTOR REGIONAL VTS CENTRO NORTE CI',
  'SUBDIRECTOR REGIONAL VTS METRO CI',
  'SUBDIRECTOR REGIONAL VTS NORESTE CI',
  'SUBDIRECTOR REGIONAL VTS OCCIDENTE CI',
  'SUBDIRECTOR REGIONAL VTS PACIFICO CI',
  'SUBDIRECTOR REGIONAL VTS PENINSULA CI',
  'SUBDIRECTOR REGIONAL VTS SUR CI',
  'SUBDIRECTOR REGIONAL VTS SURESTE CI',
  'GERENTE REGIONAL CI',
  'GERENTE CI',
  'GERENTE REGIONAL OPERACIONES SERVICIO',
  'SUBDIRECTOR OPER FRONT OFFICE CENTRO',
  'SUBDIRECTOR OPER FRONT OFFICE NORTE',
  'SUBDIRECTOR OPER FRONT OFFICE SUR',
  'COORDINADOR REG OPERACIONES SERVICIO',
  'GERENTE VENTAS',
  'SUBDIRECTOR VENTAS CENTRO',
  'SUBDIRECTOR VENTAS NORTE',
  'SUBGERENTE VENTAS'
];

export default positions
