import React, { useState, useEffect } from 'react';
import LeftArrow from '../assets/arrow-left.png';
import Upload from '../assets/upload-logo.png';
import Filter from '../assets/filter-logo.png';
import Download from '../assets/download-logo.png';
import Compare from '../assets/compare-logo.png';
import SeeDetails from '../assets/see_details.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import QuestionMark from '../assets/circle-info.svg';
import UploadModal from '../components/uploadModal/uploadModal';
import TrackAnualFilterModal from '../components/trackAnualFilterModal/trackAnualFilterModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCapacitaciones } from '../services/getCapacitacion';
import { uploadExcelAvancePrograma } from '../services/uploadExcelAvancePrograma';
import { uploadExcelAvanceAcreditacion } from '../services/uploadExcelAvanceAcreditacion';
import { downloadCapacitaciones } from '../services/downloadCapacitaciones';
import { AppState } from '../types/types';
import { uploadExcelEncuestaPar } from '../services/uploadExcelEncuestaPar';
import { uploadExcelEncuestaLider } from '../services/uploadExcelEncuestaLider';
import '../styles/Reports.css';
import moment from 'moment';
function Reports() {
  const [formadores, setFormadores] = useState<any[]>([]); // Asegura que formadores se inicialice como un array vacío
  const [updateDate, setUpdateDate] = useState<string>(''); // Asegura que formadores se inicialice como un array vacío

  const [loadingData, setLoadingData] = useState<boolean>(false);
  false;
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsLimit, setRowsLimit] = useState<number>(10);
  const [pagination, setPagination] = useState<number>(1);
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [actionName, setActionName] = useState<string>('');
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [uploadFormadoresError, setUploadFormadoresError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [uploadingMessage, setUploadingMessage] = useState('Cargando Datos...');
  const [groupsFiltersModal, setGroupsFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState({
    limit: rowsLimit,
    page: pagination,
    nombre: '',
    puesto: '',
    direccion: '',
    subdireccion: '',
    os: '',
    nomina: ''
  });
  const [colaboradoresUnicos] = useState<string[]>([]);
  const [puestosUnicos] = useState<string[]>([]);
  const [params, setSearchParams] = useSearchParams();

  const rolUsuario = useSelector((state: AppState) => state.user.userData?.role);

  useEffect(() => {
    fetchData();
  }, [filters, params]);

  const fetchData = async () => {
    setLoadingData(true);

    const token = sessionStorage.getItem('token');

    if (token) {
      try {
        const page = params?.get('page');
        const limit = params?.get('limit');

        const filtersMerged: any = {
          ...filters,
          ...(page && { page }),
          ...(limit && { limit })
        };

        const response: any = await getCapacitaciones(token, filtersMerged);
        setUpdateDate(response.fecha);
        setFormadores(response.capacitaciones);
        setPaginationInfo(response.pagination);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setFormadores([]);
      }
    } else {
      console.error('Token no disponible');
    }

    setLoadingData(false);
  };

  const closeAlert = () => {
    setUploadError(false);
    setUploadSuccess(false);
    setUploadFormadoresError(false);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const isGroupsFiltersModal = (openFiltersModal: boolean) => {
    setGroupsFiltersModal(openFiltersModal);
  };

  const onClose = () => {
    setUpdateModal(false);
  };

  const handleCargarAvanceDelProgramaClick = () => {
    setActionName('avancePrograma');
    setUpdateModal(true);
  };

  const handleCargarAvanceDeLaAcreditacionClick = () => {
    setActionName('avanceAcreditacion');
    setUpdateModal(true);
  };

  const handleCargarArchivoEncuestaParClick = () => {
    setActionName('encuestaPar');
    setUpdateModal(true);
  };
  const handleCargarArchivoEncuestaLiderClick = () => {
    setActionName('encuestaLider');
    setUpdateModal(true);
  };
  const handleFiltering = (
    newFilters: {
      nombre?: string | null;
      puesto?: string | null;
      direccion?: string | null;
      subdireccion?: string | null;
      os?: string | null;
      nomina?: string | null;
    },
    newLimit?: number
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      nombre: newFilters.nombre ?? '',
      puesto: newFilters.puesto ?? '',
      direccion: newFilters.direccion ?? '',
      subdireccion: newFilters.subdireccion ?? '',
      os: newFilters.os ?? '',
      nomina: newFilters.nomina ?? '',
      page: 1,
      limit: newLimit ?? prevFilters.limit
    }));
  };

  const restaurarFiltros = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      nombre: '',
      puesto: '',
      direccion: '',
      subdireccion: '',
      os: '',
      nomina: '',
      page: 1
    }));
    setPagination(1);
  };

  const descargarComoExcel = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      downloadCapacitaciones(token, filters)
        .then((result) => {
          window.open(result.url, '_blank');
        })
        .catch((error) => {
          console.error('Error al descargar el archivo de capacitaciones', error);
        });
    } else {
      console.log('No se encontró token de autenticación');
    }
  };

  const handleRowsLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLimit = parseInt(e.target.value, 10);
    setSearchParams((prev: URLSearchParams) => {
      prev?.set('limit', newLimit?.toString());
      return prev;
    });
  };

  const nextPage = () => {
    const page: number = Math.min(paginationInfo.page + 1, paginationInfo.totalPages);
    setSearchParams((prev: URLSearchParams) => {
      prev?.set('page', page?.toString());
      return prev;
    });
  };

  const previousPage = () => {
    const page: number = Math.max(paginationInfo.page - 1, 1);
    setSearchParams((prev: URLSearchParams) => {
      prev?.set('page', page?.toString());
      return prev;
    });
  };

  const navigate = useNavigate();

  const goToDetails = (nomina: any) => {
    const colaborador = formadores.find((formador: { nomina: any }) => formador.nomina === nomina);

    if (colaborador) {
      navigate(
        `/trackAnual/detalledeTrackCapacitacion/${nomina}?prev=${encodeURIComponent(params?.toString())}`,
        {
          state: {
            colaborador
          }
        }
      );
    } else {
      console.log('Colaborador no encontrado para la nómina:', nomina);
    }
  };

  const handleFileUpload = async (base64Data: string, fileName: string) => {
    setLoadingData(true);
    setIsUploading(true);
    setUploadingMessage('Cargando Datos...');

    setTimeout(() => {
      setLoadingData(false);
      setUploadingMessage('Se subirá el excel pronto');

      setTimeout(async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
          setErrorMessage('Error: No se encontró el token de sesión.');
          setUploadError(true);
          setIsUploading(false);
          setTimeout(closeAlert, 2000);
          return;
        }

        let response;
        try {
          if (actionName === 'avancePrograma') {
            response = await uploadExcelAvancePrograma(base64Data, token);
          } else if (actionName === 'avanceAcreditacion') {
            response = await uploadExcelAvanceAcreditacion(base64Data, token);
          } else if (actionName === 'encuestaPar') {
            response = await uploadExcelEncuestaPar(base64Data, token);
          } else if (actionName === 'encuestaLider') {
            response = await uploadExcelEncuestaLider(base64Data, token);
          } else {
            throw new Error('Acción no reconocida');
          }

          setIsUploading(false);

          if (response && response.success) {
            setSuccessMessage(`La subida del archivo ${fileName} fue exitosa.`);
            setUploadSuccess(true);
          } else {
            setErrorMessage(response.message || 'Error en la carga del archivo.');
            setUploadError(true);
          }
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Error inesperado en la carga del archivo.';
          setErrorMessage(errorMessage);
          setUploadError(true);
        } finally {
          setTimeout(closeAlert, 2000);
        }
      }, 2000);
    }, 2000);
  };

  const getProgressGradient = (percentage: number) => {
    if (percentage === 100) {
      return 'linear-gradient(to right, #008000, #0DFF00)'; // Verde oscuro a verde brillante - Completado
    } else if (percentage >= 75) {
      return 'linear-gradient(to right, #0040FF, #00CFFF)'; // Azul oscuro a azul claro - Casi completo
    } else if (percentage >= 50) {
      return 'linear-gradient(to right, #FFD700, #FFA500)'; // Dorado a naranja - Mitad de camino
    } else if (percentage >= 25) {
      return 'linear-gradient(to right, #FF0000, #FF4500)'; // Rojo a rojo-anaranjado - Progreso inicial
    } else if (percentage >= 10) {
      return 'linear-gradient(to right, #800080, #DA70D6)'; // Púrpura a orquídea - Alerta baja
    } else if (percentage > 0) {
      return 'linear-gradient(to right, #4B0082, #0000FF)'; // Índigo a azul - Progreso muy bajo
    } else {
      return 'linear-gradient(to right, #808080, #A9A9A9)'; // Gris oscuro a gris claro - Sin progreso
    }
  };

  const filteredFormadores =
    searchTerm.length === 0
      ? formadores
      : formadores.filter((formador) => {
          return (
            formador.nomina.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            formador.nombre.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });

  return (
    <div className="container colaboradores scrollable-container bootstrap-wrapper">
      {updateDate !== '' && updateDate !== undefined && (
        <h4
          style={{ margin: '4px 0' }}
        >{`Última actualización: ${moment.utc(updateDate).format('DD/MM/YYYY')}`}</h4>
      )}
      <div className="table-options-reports">
        <div className="action-buttons-container">
          <div className="left">
            <div className="action-button" onClick={restaurarFiltros}>
              <img src={Compare} alt="restore-icon" />
              <p>RESTAURAR FILTROS</p>
            </div>
            <div className="action-button" onClick={() => setGroupsFiltersModal(true)}>
              <img src={Filter} alt="upload-logo" />
              <p>FILTRAR</p>
            </div>
          </div>
          <div className="right">
            <div className="action-button" onClick={descargarComoExcel}>
              <img src={Download} alt="compare-logo" />
              <p>DESCARGAR</p>
            </div>
          </div>

          {/*   <div className="option-download hidden-lg-up" onClick={descargarComoExcel}>
            <img src={Download} alt="compare-logo" />
            <p>DESCARGAR</p>
          </div> 
     <div className="option-download hidden-lg-down" onClick={descargarComoExcel}>
          <img src={Download} alt="compare-logo" />
          <p>DESCARGAR</p>
        </div> */}
        </div>

        {/*  <div className="table-options-separate hidden-md-up">
          {rolUsuario === 'admin' && (
            <>
              <div className="option" onClick={handleCargarAvanceDelProgramaClick}>
                <img src={Upload} alt="upload-logo" />
                <p>CARGAR AVANCE DEL PROGRAMA</p>
              </div>
              <div className="option" onClick={handleCargarAvanceDeLaAcreditacionClick}>
                <img src={Upload} alt="upload-logo" />
                <p>CARGAR AVANCE DE LA ACREDITACION</p>
              </div>
              <div className="option" onClick={handleCargarArchivoEncuestaParClick}>
                <img src={Upload} alt="upload-logo" />
                <p>CARGAR ENCUESTA PAR</p>
              </div>
              <div className="option" onClick={handleCargarArchivoEncuestaLiderClick}>
                <img src={Upload} alt="upload-logo" />
                <p>CARGAR ECUESTA LIDER</p>
              </div>
            </>
          )}
        </div> */}

        {rolUsuario === 'admin' && (
          <div className="load-buttons-container">
            <div className="option" onClick={handleCargarAvanceDelProgramaClick}>
              <img src={Upload} alt="upload-logo" />
              <p>CARGAR AVANCE DEL PROGRAMA</p>
            </div>
            <div className="option" onClick={handleCargarAvanceDeLaAcreditacionClick}>
              <img src={Upload} alt="upload-logo" />
              <p>CARGAR AVANCE DE LA ACREDITACION</p>
            </div>
            <div className="option" onClick={handleCargarArchivoEncuestaParClick}>
              <img src={Upload} alt="upload-logo" />
              <p>CARGAR ENCUESTA PAR</p>
            </div>
            <div className="option" onClick={handleCargarArchivoEncuestaLiderClick}>
              <img src={Upload} alt="upload-logo" />
              <p>CARGAR ENCUESTA LIDER</p>
            </div>
          </div>
        )}

        {groupsFiltersModal && (
          <TrackAnualFilterModal
            isGroupsFiltersModal={isGroupsFiltersModal}
            onApplyFilters={handleFiltering}
            colaboradores={colaboradoresUnicos}
            puestos={puestosUnicos}
          />
        )}
        {updateModal && (
          <UploadModal
            onClose={onClose}
            uploadService={handleFileUpload}
            title={`Cargar Archivo de ${actionName.charAt(0).toUpperCase() + actionName.slice(1)}`} // Capitaliza la primera letra
            isLoading={isUploading}
            setIsLoading={setLoadingData}
            successCallback={(message) => {
              console.log(message);
            }}
            errorCallback={(message) => {
              console.error(message);
            }}
          />
        )}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadSuccess && (
        <div className="update-file-response-container-success">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>{successMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadError && (
        <div className="update-file-response-container-error">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>{errorMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {isUploading && (
        <div className="update-file-response-container-info">
          <div className="update-file-message">
            <img src={QuestionMark} />
            <p>{uploadingMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {uploadFormadoresError === true && (
        <div className="update-file-response-container-error">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>{errorMessage}</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {formadores === null ? (
        'No data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header">
              <tr>
                <th className="sticky">Acciones</th>
                <th className="sticky-second">Nómina</th>
                <th className="sticky-third">Colaborador</th>
                <th>% De avance general</th>
                <th>Puesto</th>
                {rolUsuario !== 'gerente' && <th>Oficina</th>}
                <th>Subdirección</th>
                <th>Dirección</th>
                {/*   <th>Última actualización</th> */}
              </tr>
            </thead>
            {filteredFormadores.map((item: any, index: number) => (
              <tbody key={index}>
                <tr className="data-table">
                  <td className="sticky">
                    <img
                      src={SeeDetails}
                      alt="edit-logo"
                      onClick={() => goToDetails(item.nomina)}
                      title="Ver detalle del track del colaborador" // Agregar esto
                      className="edit-logo"
                    />
                  </td>
                  <td className="sticky-second">{item.nomina}</td>
                  <td className="sticky-third text-left ">{item.nombre}</td>
                  <td>
                    <div
                      className="progress-container-2"
                      aria-label={`Progress: ${item.avance_general}%`}
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: `${Math.round(item.porcentaje)}%`,
                          background: getProgressGradient(item.porcentaje)
                        }}
                      >
                        <span className="progress-percentage">{Math.round(item.porcentaje)}</span>
                      </div>
                    </div>
                  </td>
                  <td>{item.puesto}</td>
                  {rolUsuario !== 'gerente' && <td>{item.os}</td>}
                  <td>{item.subdireccion}</td>
                  <td>{item.direccion}</td>
                  {/*      <td>
                    {item.updatedAt !== null ? moment.utc(item.updatedAt).format('DD/MM/YYYY') : ''}
                  </td> */}
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={paginationInfo?.limit} onChange={handleRowsLimitChange}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : `${paginationInfo.page * paginationInfo.limit - (paginationInfo.limit - 1)} - 
                 ${
                   paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                     ? paginationInfo.page * paginationInfo.limit
                     : paginationInfo.total
                 } de ${paginationInfo.total}`}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
