import React, { useEffect, useState } from 'react';
import '../styles/styles.css';
import { getColaboradores } from '../services/getColaboradores';
import Upload from '../assets/upload-logo.png';
import Filter from '../assets/filter-logo.png';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import UpdateDbModal from '../components/updateDbModal/updateDbModal';
import ColabsFilterModal from '../components/colabsFilterModal/colabsFilterModal';
import moment from 'moment';
import QuestionMark from '../assets/circle-info.svg';
interface Prueba {
  nombre: string;
  nomina: number;
  role: string;
}

function Employees() {
  const [updateDate, setUpdateDate] = useState<string>('');

  const [colaboradores, setColaboradores] = useState<any>(null);
  const [updateDbModal, setUpdateDbModal] = useState<boolean>(false);
  const [filtersModal, setFiltersModal] = useState<boolean>(false);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [eventoFilter, setEventoFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [updateColabsAns, setUpdateColabsAns] = useState<boolean | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const isUpdateDbModal = (openModal: boolean) => {
    setUpdateDbModal(openModal);
  };

  const isfiltersModal = (openFiltersModal: boolean) => {
    setFiltersModal(openFiltersModal);
  };

  const isEventoFilter = (eventoFilter: string) => {
    setEventoFilter(eventoFilter);
  };

  const isLoadingAlert = (loadAlert: boolean) => {
    setLoadingData(loadAlert);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

  const nextPage = () => {
    if (pagination < paginationInfo.totalPages) {
      setPagination(pagination + 1);
    }
  };

  const previousPage = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
    }
  };

  const isPostSuccess = (postResponse: boolean) => {
    setUpdateColabsAns(postResponse);
  };

  const closeAlert = () => {
    if (isUploading) {
      setIsUploading(false);
    }
    setUpdateColabsAns(null);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getColaboradores(token, rowsLimit, pagination, eventoFilter)
          .then((result) => {
            setColaboradores(result.data.colaboradores);
            setUpdateDate(result.data.fecha);

            setPaginationInfo(result.data.pagination);
          })
          .catch((error) => {
            console.error('Error al obtener usuario', error);
          });
      }
    };
    apiCall();
    if (updateColabsAns === true) {
      apiCall();
      setLoadingData(false);
      setTimeout(() => {
        setUpdateColabsAns(null);
      }, 5000);
    }
    if (updateColabsAns === false) {
      setLoadingData(false);
      setTimeout(() => {
        setUpdateColabsAns(null);
      }, 5000);
    }
  }, [rowsLimit, eventoFilter, pagination, updateColabsAns]);

  return (
    <div className="container colaboradores">
      {updateDate !== '' && updateDate !== undefined && (
        <h4
          style={{ margin: '4px 0' }}
        >{`Última actualización: ${moment.utc(updateDate).format('DD/MM/YYYY')}`}</h4>
      )}
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}

      {isUploading && (
        <div className="update-file-response-container-info">
          <div className="update-file-message">
            <img src={QuestionMark} />
            <p>Se subirá el excel pronto</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}

      {updateColabsAns === true && (
        <div className="update-file-response-container-success">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>La base de datos se actualizó satisfactoriamente</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateColabsAns === false && (
        <div className="update-file-response-container-error">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>
              Hubo un problema con el sistema y la base de datos no se actualizó, por favor,
              inténtalo de nuevo
            </p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      <div className="table-options">
        <div className="option" onClick={() => setUpdateDbModal(true)}>
          <img src={Upload} alt="upload-logo" />
          <p>ACTUALIZAR BASE DE DATOS</p>
        </div>
        {updateDbModal && (
          <UpdateDbModal
            isUpdateDbModal={isUpdateDbModal}
            isPostSuccess={isPostSuccess}
            isLoadingAlert={isLoadingAlert}
            setIsUploading={setIsUploading}
          />
        )}
        {filtersModal && (
          <ColabsFilterModal isfiltersModal={isfiltersModal} isEventoFilter={isEventoFilter} />
        )}
        <div className="option" onClick={() => setFiltersModal(true)}>
          <img src={Filter} alt="filter-logo" />
          <p>FILTRAR</p>
        </div>
      </div>

      {colaboradores === null ? (
        'No data'
      ) : (
        <div className="table-container scrollable-container">
          <table className="data-table-container">
            <thead className="table-header">
              <tr>
                <th>Nómina</th>
                <th className="colabs-header">Colaborador</th>
                <th>Fecha de Contratación</th>
                <th>Evento</th>
                <th>Motivo del Evento</th>
                <th>Fecha del evento</th>
                <th>Estado de colaborador</th>
                <th>Posición</th>
                <th>Puesto</th>
                <th>Ubicación</th>
                <th>Dirección</th>
                <th>Subdirección</th>
                <th>Dirección ejecutiva</th>
                <th>Centro de costos</th>
                <th>ID de sistema del usuario jefe inmediato</th>
                <th>Jefe inmediato</th>
                <th>CURP</th>
                <th>Fecha de inicio en la posición</th>
                <th>Fecha de inicio en el puesto</th>
                <th>Descripción corta de SAP</th>
                <th>Fecha de nacimiento</th>
                <th>Género</th>
                <th>Negocio</th>
                {/*   <th>Última actualización</th> */}
              </tr>
            </thead>
            {colaboradores.map((item: any, index: number) => (
              <tbody key={index}>
                <tr className="data-table">
                  <td>{item.user.nomina}</td>
                  <td className="name-container colabs-table">
                    <p>{item.user.nombre}</p>
                  </td>
                  <td>
                    {item.fecha_contratacion !== null
                      ? moment.utc(item.fecha_contratacion).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{item.evento}</td>
                  <td>{item.motivo_evento}</td>
                  <td>
                    {item.fecha_evento !== null
                      ? moment.utc(item.fecha_evento).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{item.estado}</td>
                  <td>{item.posicion}</td>
                  <td>{item.puesto}</td>
                  <td>{item.ubicacion}</td>
                  <td>{item.direccion}</td>
                  <td>{item.subdireccion}</td>
                  <td>{item.direccion_ejecutiva}</td>
                  <td>{item.centro_costos}</td>
                  <td>{item.id_jefe}</td>
                  <td>{item.jefe}</td>
                  <td>{item.curp}</td>
                  <td>
                    {item.fecha_posicion !== null
                      ? moment.utc(item.fecha_posicion).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>
                    {item.fecha_puesto !== null
                      ? moment.utc(item.fecha_puesto).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{item.sap}</td>
                  <td>
                    {item.user.fecha_nacimiento !== null
                      ? moment.utc(item.user.fecha_nacimiento).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td>{item.user.genero}</td>
                  <td>{item.negocio}</td>
                  {/*      <td>
                    {item.updatedAt !== null ? moment.utc(item.updatedAt).format('DD/MM/YYYY') : ''}
                  </td> */}
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Employees;
