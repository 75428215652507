import React, { useEffect, useState } from 'react';
import './editGroupsSedeModal.css';
import Quit from '../../assets/quit.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditDataSelectorSedes from '../editDataSelectors/editDataSelectorSedes';
import EditDataSelectorDireccion from '../editDataSelectors/editDataSelectorDireccion';
import EditDataSelectorSubdireccion from '../editDataSelectors/editDataSelectorSubdirecciones';
import EditDataSelectorRegionYastas from '../editDataSelectors/editDataSelectorRegionYastas';
import EditDataSelectorSubdireccionYastas from '../editDataSelectors/editDataSelectorSubdireccionYastas';
import EditDataSelectorGrupos from '../editDataSelectors/editDataSelectorGrupos';
import EditDataSelectorSubdirector from '../editDataSelectors/editDataSelectorSubdirector';
import EditDataSelectorHoteles from '../editDataSelectors/editDataSelectorHoteles';
import EditDataSelectorSedesGrupos from '../editDataSelectors/editDataSelectorSedesGrupos';
import { updateMiembro } from '../../services/updateMiembro';
import { getFormadores } from '../../services/getFormadores';

function EditGroupsSedeModal({ isEditarSedes, grupo, colaborador, selectedDate }: any) {
  const [selectedStartDate, setSelectedStartDate] = useState<any>('');
  const [selectedFinishDate, setSelectedFinishDate] = useState<any>('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [finishDate, setFinishDate] = useState<Date | null>(new Date());
  const [groupsDate, setGroupsDate] = useState<string>(selectedDate);
  const [newDireccion, setNewDireccion] = useState<string>('');
  const [newSubdireccion, setNewSubdireccion] = useState<string>('');
  const [newRegionYastas, setNewRegionYastas] = useState<string>('');
  const [newSubdireccionYastas, setNewSubdireccionYastas] = useState<string>('');
  const [sede, setSede] = useState<string>('');
  const [asistencia, setAsistencia] = useState<boolean>(true);
  const [hospedaje, setHospedaje] = useState<boolean>(true);
  const [servicios, setServicios] = useState<boolean>(true);
  const [noches, setNoches] = useState<string>('');
  //console.log(asistencia);
  //console.log('colaborador', colaborador);

  const closeEditUserModal = () => {
    isEditarSedes(false);
  };

  const onStartDateChange = (date: any) => {
    setStartDate(date);
    const dateFormatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setSelectedStartDate(dateFormatted);
  };

  const onFinishDateChange = (date: any) => {
    setFinishDate(date);
    const dateFormatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setSelectedFinishDate(dateFormatted);
  };

  const onAsistenciaChange = (e: any) => {
    const asistenciaOption = e.target.value;
    asistenciaOption === 'Asistencia' ? setAsistencia(true) : setAsistencia(false);
  };

  const onHospedajeChange = (e: any) => {
    const hospedajeOption = e.target.value;
    hospedajeOption === 'Si' ? setHospedaje(true) : setHospedaje(false);
  };

  const onServiciosChange = (e: any) => {
    const serviciosOption = e.target.value;
    serviciosOption === 'Si' ? setServicios(true) : setServicios(false);
  };

  const onNochesChange = (e: any) => {
    const noches = e.target.value;
    setNoches(noches);
  };

  const getDireccion = (direccion: string) => {
    setNewDireccion(direccion);
  };
  const getSubdireccion = (subdireccion: string) => {
    setNewSubdireccion(subdireccion);
  };
  const getSubdirector = (subdirector: string) => {
    console.log('pending');
  };
  const getNewRegionYastas = (regionYastas: string) => {
    setNewRegionYastas(regionYastas);
  };
  const getNewSubdireccionYastas = (subdireccionYastas: string) => {
    setNewSubdireccionYastas(subdireccionYastas);
  };
  const getSede = (sede: string) => {
    setSede(sede);
  };
  const getHotel = (hotel: string) => {
    console.log('pending');
  };
  const getGrupo = (grupo: string) => {
    console.log('pending');
  };

  const UpdatedInfo = {
    grupo_id: grupo.id,
    ubicacion: sede,
    hospedaje: hospedaje,
    //hotel: null,
    servicios: servicios,
    noches: noches
  };

  const uploadColaboradorInfo = () => {
    const token: any = sessionStorage.getItem('token');
    const data: any = UpdatedInfo;
    updateMiembro(token, data).then((response) => {
      //setPostResponse(response);
      console.log(response);
    });
    closeEditUserModal();
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getFormadores(token)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.error('Error al obtener usuario', error);
          });
      }
    };
    apiCall();
  });

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container asignar-formador">
        <div className="colabs-filter-header">
          <p>Editar Sede</p>
          <img src={Quit} alt="quit-logo" onClick={closeEditUserModal} />
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option edit-sede-subtitle">Sede del curso</p>
          <div className="direccion-selector selector-sede">
            <p>Dirección</p>
            <EditDataSelectorSedesGrupos grupo={grupo} />
          </div>
          <div className="hospedaje">
            <p>Hospedaje</p>
            <div className="hospedaje-options" onChange={onHospedajeChange}>
              <label>
                <input type="radio" name="hospedaje" value="Si" />
                Si
              </label>
              <label>
                <input type="radio" name="hospedaje" value="=No" />
                No
              </label>
            </div>
          </div>
          <div className="direccion-selector subdireccion-selector">
            <p>Hotel</p>
            <EditDataSelectorHoteles />
          </div>
          <div className="hospedaje">
            <p>Servicios</p>
            <div className="hospedaje-options" onChange={onServiciosChange}>
              <label>
                <input type="radio" name="servicios" value="Si" />
                Si
              </label>
              <label>
                <input type="radio" name="servicios" value="No" />
                No
              </label>
            </div>
          </div>
          <div className="direccion-selector subdireccion-selector noches-container">
            <p>Noches</p>
            <input
              type="text"
              placeholder="Escribe el número de noches"
              className="noches-input"
              onChange={onNochesChange}
            />
          </div>
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={closeEditUserModal}>
            CANCELAR
          </button>
          <button className="accept-button" onClick={uploadColaboradorInfo}>
            GUARDAR CAMBIOS
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditGroupsSedeModal;
